/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {


// MOBILE MENU 

                $(".btnMobile").click(function () {
                    $(".mobileMenu").toggle();
                });


                $(".burger-menu").click(function () {
                    $(this).toggleClass("menu-on");
                });


                // sticky nav
                $(function () {
                    var hasBeenTrigged = false;
                    $(window).scroll(function () {
                        if ($(this).scrollTop() >= 70 && !hasBeenTrigged) { // if scroll is greater/equal then 100 and hasBeenTrigged is set to false.
                            $(".nl_menu__main").addClass("sticky-nav");
                        }
                        if ($(this).scrollTop() <= 70 && !hasBeenTrigged) { // if scroll is greater/equal then 100 and hasBeenTrigged is set to false.
                            $(".nl_menu__main").removeClass("sticky-nav");
                        }

                    });
                });




                $('.slider__events-widget').slick(
                        {
                            arrows: false,
                            dots: false,
                            fade: true,
                            cssEase: 'linear',

                            autoplay: true,
                            infinite: false,
                            speed: 400,
                            slidesToShow: 1,
                            slidesToScroll: 1

                        }
                );
                $('.carsl--event-main').slick(
                        {
                            arrows: false,
                            autoplay: false,
                            dots: false,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 4,
                            slidesToScroll: 1
                                    /*             centerMode: true,
                                     */
                        }

                );
                $('.carsl--event-main_mobile').slick(
                        {
                            arrows: false,
                            autoplay: true,
                            centerMode: true,

                            dots: false,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 1,
                            slidesToScroll: 1,

                        }

                );

                $('.slider--menu').slick(
                        {
                            arrows: true,
                            dots: false,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 3,
                            slidesToScroll: 2,
                            centerMode: true

                        }

                );

                $('.slider--categorie').slick(
                        {
                            arrows: true,
                            dots: false,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 3,
                            slidesToScroll: 2,
                            centerMode: true

                        }

                );



                //$("#example").newsTicker();



                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {

                $('.slider__events').slick(
                        {

                            arrows: false,
                            dots: false,
                            autoplay: true,
                            infinite: false,
                            speed: 300,
                            slidesToShow: 2,
                            slidesToScroll: 1

                        }
                );
                $('.slider__sliderone').slick(
                        {

                            arrows: false,
                            dots: false,
                            autoplay: true,
                            infinite: false,
                            speed: 300,
                            slidesToShow: 3,  
                            slidesToScroll: 1,
                            responsive: [
                                {
                                    breakpoint: 991,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1,
                                        centerMode: true,

                                        infinite: true,
                                        dots: true
                                    }
                                },
                                {
                                    breakpoint: 767,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                    }
                                }
                        
                            ]



                        }
                );



                $('.slider__events-weekend').slick(
                        {
                            arrows: false,
                            dots: true,
                            autoplay: true,
                            infinite: true,
                            slidesToShow: 1,
                            slidesToScroll: 1

                        }
                );


                $('.news__carsl').slick(
                        {
                            arrows: false,
                            autoplay: true,

                            dots: false,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 2,
                            slidesToScroll: 2

                        }

                );



            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.




